import * as React from "react"
import { PageProps } from "gatsby"

// Components
import Layout from "components/Layout"
import Main from "components/Layout/Main"
import DefaultSidebar from "components/Sections/Sidebar/DefaultSidebar"
import Note from "components/UIElements/Note"
// Models
import { IOptionalMetaProps } from "models/Seo"

const NotFoundPage: React.FC<PageProps> = () => {
  const seo: IOptionalMetaProps = {
    title: "Página no encontrada :(",
  }

  return (
    <Layout seo={seo}>
      <Main sidebar>
        <h2 className="title">Página no encontrada</h2>
        <Note>No pudimos encontrar la página que estás buscando 😢</Note>
      </Main>
      <DefaultSidebar />
    </Layout>
  )
}

export default NotFoundPage
